.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  max-width: 1200px;
  margin: 0 auto;
}

.landing_header__logo {
  max-height: 50px;
  max-width: 200px;
}

.header__counter {
  font-size: 16px;
  position: absolute;
  right: 16px;
}
