.hero-container {
  text-align: center;
  padding: 20px;
  max-width: 360px;
  margin: 0 auto;
}

.hero-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.hero-subtitle {
  font-size: 18px;
  margin-bottom: 20px;
}

.hero-quiz-invite {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

.hero-gender-selection {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.hero-book-img {
  max-width: 100%;
  width: 240px;
}

.gender-card {
  cursor: pointer;
  width: 150px;
  padding: 0px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  position: relative;
  overflow: visible;
  text-align: center;
}

.male-card {
  background-color: #BCE0FD;
}

.female-card {
  background-color: #FAD0C4;
}

.gender-image-container {
  margin-bottom: -5px;
}

.gender-card img {
  width: 128px;
  height: auto;
  margin-top: -40px;
  border-radius: 10px;
}

.gender-button {
  background-color: purple;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  display: block;
  margin: 0 auto;
}

.male-button {
  background-color: #02405E;
}

.female-button {
  background-color: #8C2E39;
}

.gender-card:hover {
  transform: scale(1.05);
}

@media (max-width: 420px) {
  .hero-container {
    padding: 20px 10px;
  }

  .hero-gender-selection {
    gap: 10px;
  }

  .gender-card {
    width: calc(50% - 5px);
    min-width: 120px;
  }

  .female-prompt {
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 12px 12px 0 0;
    max-width: none;
    margin: 0;
    padding: 16px;
  }

  .gender-button {
    padding: 18px 15px;
    font-size: 14px;
  }
}

.female-section {
  position: relative;
  width: 100%;
}

.female-prompt-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
  max-width: 320px;
}

.female-prompt {
  position: absolute;
  bottom: calc(100% + 16px);
  left: 0%;
  transform: translateX(-50%);
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 320px;
  max-width: calc(100vw - 80px);

  text-align: center;
  margin: 0 auto;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  color: #666;
  cursor: pointer;
  padding: 5px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.close-button:hover {
  background-color: #f5f5f5;
  color: #333;
}

.female-prompt::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 70%;
  transform: translateX(-50%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid white;

}

.prompt-title {
  color: #8C2E39;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
}

.prompt-message {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 12px;
  color: #333;
}

.prompt-share-text {
  /* font-weight: bold; */
  font-size: 14px;
  color: #666;
  margin-bottom: 16px;
}

.share-button {
  background-color: #02405E;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 180px;
}

.share-button:hover {
  background-color: #035277;
}

@media (max-width: 420px) {
  .female-prompt {
    width: calc(100vw - 40px);
    font-size: 14px;
  }
  
  .prompt-title {
    font-size: 18px;
  }
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
}

.share-button:active {
  transform: scale(0.98);
}
