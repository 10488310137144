.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    max-width: 1000px;
    margin: 0 auto;
  }

  .header_wrapper {
    background-color: #f8f9fa;
  }
  
  .quiz_header__logo {
    max-height: 50px;
    max-width:160px;
    width: 100%;
    /* object-fit: cover; */
  }
  
  .header__counter {
    font-size: 16px;
  }
  
  