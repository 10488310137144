.hero-container {
  text-align: center;
  padding: 20px;
  max-width: 360px;
  margin: 0 auto;
}

.hero-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.hero-subtitle {
  font-size: 18px;
  margin-bottom: 20px;
}

.hero-quiz-invite {
  font-size: 16px;
  color: #555;
  margin-bottom: 60px;
}

.hero-gender-selection {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.gender-card {
  cursor: pointer;
  width: 320px; /* Set the default width */
  max-width: 100%; /* Ensure it doesn't exceed the width of its container */
  padding: 0px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  position: relative;
  overflow: visible;
  text-align: center; /* Align the contents of the gender card */
  margin-bottom: 20px; /* Add space between cards when they wrap */
}

.male-card {
  background-color: #BCE0FD; /* Example light blue background for the male card */
}

.female-card {
  background-color: #FAD0C4; /* Example pink background for the female card */
}

/* ... (additional styles for gender-card, gender-button, etc.) */

.gender-image-container {
  margin-bottom: -5px; /* Space between the image and the button */
}

.gender-card img {
  width: 128px;
  height: auto;
  margin-top: -40px; /* Move the image up */
  border-radius: 10px;
}

.gender-button {
  background-color: purple; /* Replace with actual color */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  display: block; /* Block display to fill the parent width */
  margin: 0 auto; /* Center the button if the card width is variable */
}

.male-button {
  background-color: #02405E; /* Replace with actual color */
}
.female-button {
  background-color: #8C2E39; /* Replace with actual color */
}
/* Add media queries and additional styling as needed */
.gender-card:hover {
  transform: scale(1.05);
}

@media (max-width: 420px) {
  .gender-card {
    width: auto; /* Allows the card to shrink */
  }
}
