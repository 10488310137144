/* variables.css */
:root {
    --color-main: #02405E;
    --button-color: #02405E;
    --button-hover-color: #02405E;
    --button-color-disabled: #C0D6DF;
    --button-color-red: #8C2E39;
    --button-text-color-disabled: #ffffff;
    --button-text-color: rgb(44, 44, 44);
    --button-text-hover-color: white;
    --unselected-button-color: #f6f6f6;
    --discount: #ff4520;
  }
  