.thanks-for-sharing-page {
  text-align: center;
  animation: fadeInSlideIn 0.3s ease-out forwards;
  max-width: 480px;
  margin: 0 auto;
}
.thanks-for-sharing-page h1 {
  margin-top: 30px;
  margin-bottom: 0rem;
  font-size: 2rem;
  font-weight: 800;
}
.thanks-for-sharing-page h2 {
  font-size: 1.25rem;
}
.thanks-for-sharing-page-padding {
  /* existing styles */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}



/* Add additional media queries as needed for responsiveness */
@media (max-width: 768px) {
  /* Adjust styles for smaller screens */
}

.thanks-for-sharing-page__text {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}


.thanks-for-sharing-page__paragraph {
font-size: 16px;
max-width: 420px;
text-align: center;
margin: 0rem 0 1rem 0;
}

.thanks-for-sharing-page__list {
list-style-type: none;
padding: 0;
}

.thanks-for-sharing-page__list li {
font-size: 1.1rem;
margin-bottom: 0.5rem;
}


.thanks-for-sharing-page__continue {
  font-size: 1rem;
  font-weight: 600;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 10rem;
  border: none;
  cursor: pointer;
  background-color: var(--button-color);
  color: white;
  transition: background-color 0.3s, color 0.3s;
  width: 100%;
  max-width: 600px;
  height: 60px;
}



.thanks-for-sharing-page__continue:hover {
background-color: var(--button-hover-color);
}


.thanks-for-sharing-page__go-back {
  display: block;
  font-size: 1rem;
  text-align: center;
  margin-top: 1rem;
  color: rgb(143, 143, 143);
  text-decoration: none;
}

.thanks-for-sharing-page__go-back:hover {
  text-decoration: underline;
}


.testimonial__image {
  max-width: 480px;
  width: 100%;
  height: 240px;
  object-fit: cover; 
}

.testimonial {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: center;
  pointer-events: none;
}

.testimonial__name {
  font-size: 1rem;
}

.testimonial__stars img {
  width: 24px;
  height: 24px;
  padding: 2px;
}

.testimonial__verified {
  display: flex;
  font-size: 0.6rem;
  align-items: center;
  gap: 4px;
  background-color: #C0D6DF;
  padding: 8px 12px;
  border-radius: 4px;
}

.testimonial__verified img {
  width: 16px;
  height: 16px;
  align-items: center;
}

@media (min-width: 540px) {
  .thanks-for-sharing-page__continue {
    max-width: 420px;
  }
}

.thanks-for-sharing-page__image {
  width: 100%;
  max-width: 260px;
  height: auto;
  margin-bottom: 0.5rem;
  border-radius: 8px;
}